<script>
  import Button from './Button.svelte';
  import { _ } from 'svelte-i18n';
  import { convertHexToRGBA, RGBAToHexA } from '../utils/colors';
  import { linkResolver } from '../utils/prismicHelpers';


  const DEFAULT_BG_COLOR = '#232323';
  export let fillColorPercentage = 0.5;
  export let backgroundColorProp;
  export let backgroundImage;
  export let heading = $_('callToAction.heading');
  export let features = [
    $_('callToAction.features.1'),
    $_('callToAction.features.2'),
    $_('callToAction.features.3'),
  ];

  export let button;

  const buttonLink = button.link.url || linkResolver(button.link);
  const buttonText = button.text || $_('callToAction.action')

  const backgroundColor = backgroundColorProp || DEFAULT_BG_COLOR;

  const hexaColor = RGBAToHexA(
    convertHexToRGBA(backgroundColor, fillColorPercentage)
  ).replace('#', '');

  let style = `--bgColor: ${convertHexToRGBA(
    backgroundColor,
    fillColorPercentage
  )};`;

  if (backgroundImage) {
    style = style.concat(
      backgroundImage
        ? `--bgImage: url(${backgroundImage}&blend-mode=normal&blend=${hexaColor});`
        : ''
    );
  }

  if (fillColorPercentage) {
    style = style.concat(
      fillColorPercentage ? `--bgOpacity: ${fillColorPercentage};` : ''
    );
  }
</script>

<div class="call-to-action-custom" class:hasImage={backgroundImage} {style}>
  <div class="container">
    <h6 class="heading">{heading}</h6>
    {#if features && features.length > 0}
      {#if features.some((f) => f.features)}
        <ul class="features">
          {#each features as featureData}
            {#if featureData}
              <li>{featureData.features}</li>
            {/if}
          {/each}
        </ul>
      {/if}
    {/if}
    <a href={buttonLink}>
      <Button large>{buttonText}</Button>
    </a>
  </div>
</div>

<style>
  .call-to-action-custom {
    background-color: var(--bgColor);
    padding: 70 0px;
    text-align: center;
    color: #ffffff;
    position: relative;
  }
  .call-to-action-custom.hasImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: var(--bgImage);
  }
  .heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 29px;
  }
  .features {
    display: flex;
    margin-bottom: 42px;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    flex-wrap: wrap;
    padding: 0;
    opacity: 0.9;
  }
  .features li {
    list-style-type: disc;
    margin: 0 22px;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    .heading {
      font-size: 24px;
    }
  }
</style>
