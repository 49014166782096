<script>
  import PrismicDOM from 'prismic-dom';
  import { linkResolver } from '../utils/prismicHelpers';

  export let question, answer;

  let show = false;

  function toggle() {
    show = !show;
  }
</script>

<style>
  .question-answer {
    max-width: 800px;
    padding-top: 55px;
    padding-bottom: 55px;
    margin: 0 auto;
  }
  .question-answer:not(:last-child) {
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
  }
  .question {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .question:hover {
    color: #888;
  }
  .answer {
    margin-top: 17px;
    font-size: 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }
  .answer.show {
    max-height: 700px;

    transition: max-height 0.3s ease-in;
  }
  .toggle {
    float: right;
    cursor: pointer;
    height: 24px;
    width: 24px;
    line-height: 32px;
  }
  .toggle:hover {
    color: #888;
  }
</style>

<div class="container question-answer">
  <img class="toggle" src="add.png" alt="Toggle" on:click={toggle} />
  <div class="question" on:click={toggle}>{question}</div>
  <div class="answer" class:show>
    {@html PrismicDOM.RichText.asHtml(answer, linkResolver)}
  </div>
</div>
