<script>
  import { IMAGE_QUALITY } from '../constants';

  export let alt;
  export let srcSet;
  export let size = 'medium';
  export let url;
  export let imgix = [];

  // TODO: apply imgix transformations
  const imgixString = `&${imgix.join('&')}`;

  const imageVw =
    size === 'Small'
      ? '25vw'
      : size === 'Medium'
      ? '50vw'
      : size === 'Large'
      ? '75vw'
      : '100vw';

  let sizes;

  if (srcSet.sm) {
    sizes = `(min width: ${srcSet.sm.dimensions.width}px) 25vw, (min width: ${srcSet.md.dimensions.width}px) 50vw, (min width: ${srcSet.lg.dimensions.width}px) 75vw, ${imageVw}`;
  }
</script>

{#if sizes}
  <img
    class="img"
    class:small={size === 'Small'}
    class:medium={size === 'Medium'}
    class:large={size === 'Large'}
    src={`${srcSet.sm.url || url}&q=${IMAGE_QUALITY}`}
    {alt}
    srcset={`${srcSet.sm.url}&q=${IMAGE_QUALITY},${srcSet.md.url}&q=${IMAGE_QUALITY} ${srcSet.md.dimensions.width}w,${srcSet.lg.url}&q=${IMAGE_QUALITY} ${srcSet.lg.dimensions.width}w,${srcSet.xl.url}&q=${IMAGE_QUALITY} ${srcSet.xl.dimensions.width}w`}
    {sizes}
  />
{:else}
  <img
    class="img"
    class:small={size === 'Small'}
    class:medium={size === 'Medium'}
    class:large={size === 'Large'}
    src={`${url}&q=${IMAGE_QUALITY}`}
    {alt}
  />
{/if}

<style>
  .img {
    max-width: 100%;
  }

  .img.small {
    max-width: 25%;
  }
  .img.medium {
    max-width: 50%;
  }

  .img.large {
    max-width: 75%;
  }
  @media (max-width: 600px) {
    .img.small {
      max-width: 100%;
      width: 100%;
    }
    .img.medium {
      max-width: 100%;
      width: 100%;
    }
    .img.large {
      max-width: 100%;
      width: 100%;
    }
  }
</style>
