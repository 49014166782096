export const convertHexToRGBA = (hex = '', opacity = 100) => {
  const tempHex = hex.replace('#', '');
  let r, g, b;

  if (hex.length === 3) {
    r = parseInt(tempHex.substring(0, 1), 16);
    g = parseInt(tempHex.substring(1, 2), 16);
    b = parseInt(tempHex.substring(2, 3), 16);
  } else {
    r = parseInt(tempHex.substring(0, 2), 16);
    g = parseInt(tempHex.substring(2, 4), 16);
    b = parseInt(tempHex.substring(4, 6), 16);
  }

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export function RGBAToHexA(rgba) {
  let ex = /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i;
  if (ex.test(rgba)) {
    let sep = rgba.indexOf(',') > -1 ? ',' : ' ';
    rgba = rgba.substr(5).split(')')[0].split(sep);

    // strip the slash if using space-separated syntax
    if (rgba.indexOf('/') > -1) rgba.splice(3, 1);

    for (let R in rgba) {
      let r = rgba[R];
      if (r.indexOf('%') > -1) {
        let p = r.substr(0, r.length - 1) / 100;

        if (R < 3) {
          rgba[R] = Math.round(p * 255);
        } else {
          rgba[R] = p;
        }
      }
    }

    let r = (+rgba[0]).toString(16),
      g = (+rgba[1]).toString(16),
      b = (+rgba[2]).toString(16),
      a = Math.round(+rgba[3] * 255).toString(16);

    if (r.length == 1) r = '0' + r;
    if (g.length == 1) g = '0' + g;
    if (b.length == 1) b = '0' + b;
    if (a.length == 1) a = '0' + a;

    return '#' + a + r + g + b;
  } else {
    return 'Invalid input color';
  }
}
