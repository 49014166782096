<script>
  export let title = '';
  export let steps = [];
</script>

<style>
  .how-it-works {
    padding: 60px 20px;
  }
  .steps-container {
    overflow-x: scroll;
    padding: 60px 40px;
    display: flex;
  }
  .steps-container::-webkit-scrollbar {
    display: none;
  }
  .steps {
    display: flex;
    margin: 0 auto;
  }
  .steps__step {
    display: flex;
    flex-direction: column;
  }
  .steps__step:nth-child(even) {
    flex-direction: column-reverse;
  }
  .steps__step-node {
    position: relative;
    margin: 4px;
  }
  .steps__step-branch {
    margin-left: 24px;
    margin-bottom: 24px;
    width: 120px;
    height: 120px;
    border: 2px dotted #f57224;
    border-radius: 0 0 0 100%;
    -moz-border-radius: 0 0 0 100%;
    -webkit-border-radius: 0 0 0 100%;
    border-top: none;
    border-right: none;
  }
  .steps__step:nth-child(even) > .steps__step-branch {
    border: 2px dotted #f57224;
    border-radius: 100% 0 0 0;
    -moz-border-radius: 100% 0 0 0;
    -webkit-border-radius: 100% 0 0 0;
    border-right: none;
    border-bottom: none;
    margin-bottom: 0;
  }
  .steps__step:last-child > .steps__step-branch {
    border: none;
  }
  .steps__step-number {
    flex: 1 0 auto;
    height: 40px;
    width: 40px;
    background-color: #f57224;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
  }
  .steps__step-number:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
  }
  .steps__step-text {
    position: absolute;
    left: 52px;
    top: 8px;
    float: right;
    font-weight: 600;
    width: 140px;
  }
</style>

<div class="how-it-works">
  {#if title}
    <h3>{title}</h3>
  {/if}
  <div class="steps-container">
    <div class="steps">
      {#each steps as step, index}
        <div class="steps__step">
          <div class="steps__step-node">
            <div class="steps__step-text">{step}</div>
            <div class="steps__step-number">{index + 1}</div>
          </div>
          <div class="steps__step-branch" />
        </div>
      {/each}
    </div>
  </div>
</div>
