<script>
  export let title;
  export let description;
  export let imageUrl;
</script>

<style>
  .page-header {
    height: 400px;
    width: 100%;
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
    overflow: auto;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-header::after {
    background: rgba(0, 0, 0, 0.4);
  }
  .page-header::after {
    content: '';
    height: auto;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .content {
    max-width: 960px;
    padding: 10px 24px;
    overflow: auto;
  }

  h1 {
    font-size: 60px;
    margin: 0;
  }
  p {
    font-size: 18px;
  }
  @media (max-width: 720px) {
    .page-header {
      height: 520px;
    }
    .content {
      overflow: auto;
    }
    h1 {
      font-size: 32px;
    }
  }
</style>

<div class="page-header" style="background-image: url({imageUrl})">
  <div class="content">
    <h1>{title}</h1>
    {#if description}
      <p>{description}</p>
    {/if}
  </div>
</div>
