<script>
  export let title, description, imageUrl;
</script>

<style>
  .banner {
    background-color: #46342e;
    width: 100%;
    height: 520px;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }
  .banner__content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
  }
  .banner__card {
    width: 553px;
    background-color: hsla(0, 0%, 100%, 0.95);
    padding: 32px 38px;
  }
  .banner__title {
    font-size: 40px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 21px;
    text-align: left;
  }
  .banner__description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  @media (max-width: 600px) {
    .banner {
      background-position-x: 65%;
    }
    .banner__content {
      align-items: flex-end;
    }
    .banner__title {
      font-size: 24px;
    }
    .banner__description {
      display: none;
    }
  }
</style>

<div class="banner" style="background-image: url({imageUrl})">
  <div class="banner__content">
    <div class="banner__card">
      <h1 class="banner__title">{title}</h1>
      <div class="banner__description">{description}</div>
    </div>
  </div>
</div>
