<script>
  import PrismicDOM from 'prismic-dom';
  import PageHeader from './PageHeader.svelte';
  import QuestionAnswer from './QuestionAnswer.svelte';
  import Button from './Button.svelte';
  import Banner from './Banner.svelte';
  import StepsDiagram from '../components/StepsDiagram.svelte';
  import Image from '../components/Image.svelte';
  import CustomCallToAction from './CustomCallToAction.svelte';
  import TextWithImageSlice from './TextWithImageSlice.svelte';
  import ButtonWithImageSlice from './ButtonWithImageSlice.svelte';
  import { linkResolver } from '../utils/prismicHelpers';

  export let slices = [];
</script>

{#each slices as { slice_type, primary, items }}
  {#if slice_type === 'heading_banner'}
    <PageHeader
      title={primary.title1[0].text}
      description={primary.description}
      imageUrl={primary.image.url}
    />
  {:else if slice_type === 'banner_alternate'}
    <Banner
      title={primary.title1[0].text}
      description={primary.description}
      imageUrl={primary.image.url}
    />
  {:else if slice_type === 'richtext' && Array.isArray(primary.text)}
    <div class="container container-text richtext">
      {@html PrismicDOM.RichText.asHtml(primary.text, linkResolver)}
    </div>
  {:else if slice_type === 'question-answer'}
    <QuestionAnswer question={primary.question} answer={primary.answer} />
  {:else if slice_type === 'image'}
    <div class="container container-text image">
      <img class="image" src={primary.image.url} alt={primary.image.alt} />
    </div>
  {:else if slice_type === 'button'}
    <div class="container container-button">
      <a href={primary.link}>
        <Button large>{primary.text}</Button>
      </a>
    </div>
  {:else if slice_type === 'steps_diagram'}
    <div class="container">
      <StepsDiagram
        title={primary.title}
        steps={items.map((item) => item.step)}
      />
    </div>
  {:else if slice_type === 'two_columns_rich_text'}
    <div class="container">
      <div
        class="text-image grid"
        class:reversed={primary.image_position === 'right'}
      >
        <div class="col-6">
          <div class="text-image__text">
            {#if Array.isArray(primary.text_left)}
              {@html PrismicDOM.RichText.asHtml(primary.text_left, linkResolver)}
            {/if}
          </div>
        </div>
        <div class="col-6">
          <div class="text-image__text">
            {#if Array.isArray(primary.text_right)}
              {@html PrismicDOM.RichText.asHtml(primary.text_right, linkResolver)}
            {/if}
          </div>
        </div>
      </div>
    </div>
  {:else if slice_type === 'text_with_image'}
    <TextWithImageSlice {primary} />
  {:else if slice_type === 'text_with_image_portrait'}
    <TextWithImageSlice {primary} />
  {:else if slice_type === 'button_with_image'}
    <ButtonWithImageSlice {primary} />
  {:else if slice_type === 'call_to_action'}
    <div class="container container-button">
      <div>
        <a href={primary.button_link.url}>
          <Button large>{primary.button_text}</Button>
        </a>
      </div>
    </div>
  {:else if slice_type === 'square_image'}
    <div class="container container-button">
      {#if items.length > 0}
        {#each items as item}
          <Image
            size={item.image_size}
            src={item.image.url}
            alt={item.image.alt}
            srcSet={item.image}
          />
        {/each}
      {:else}
        <img src={primary.image1.url} alt={primary.image1.alt} />
      {/if}
    </div>
  {:else if slice_type === 'rectangle_image'}
    <div class="container container-button">
      {#if items.length > 0}
        {#each items as item}
          <Image
            size={item.image_size}
            src={item.image.url}
            alt={item.image.alt}
            srcSet={item.image}
          />
        {/each}
      {:else}
        <img src={primary.image1.url} alt={primary.image1.alt} />
      {/if}
    </div>
  {:else if slice_type === 'call_to_action_2'}
    <CustomCallToAction
      fillColorPercentage={primary.fill_color_percentage}
      heading={primary.heading && primary.heading[0] ? primary.heading[0].text : undefined}
      features={items}
      button={{ link: primary.button_link, text: primary.button_text }}
      backgroundColor={primary.background_color}
      backgroundImage={primary.background_image.url}
    />
  {:else if slice_type === 'video'}
    <div class="container video">
      {@html primary.video[0].text}
    </div>
  {/if}
  

{/each}

<style>
  .richtext {
    margin: 60px auto;
  }
  .text-image {
    padding: 40px 0;
  }
  .text-image__text {
    padding: 0 20px 20px;
  }

  .grid.reversed {
    flex-direction: row-reverse;
  }
  .image {
    width: 100%;
  }
  a {
    color: #f57224;
    font-weight: 600;
  }
  .container-button {
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
  }
  .video {
    text-align: center;
    padding: 40px 0;
  }
</style>
