<script>
  import Button from './Button.svelte';
  import Image from '../components/Image.svelte';
  import PrismicDOM from 'prismic-dom';
  import { linkResolver } from '../utils/prismicHelpers';


  export let primary;
  export let buttonPlacement = primary.button_placement || 'Left';
  const buttonOffset = Number(primary.button_top_offset) || 0;
</script>

{#if buttonPlacement === 'On top'}
  <div class="container">
    {#if Array.isArray(primary.heading)}
      {@html PrismicDOM.RichText.asHtml(primary.heading, linkResolver)}
    {/if}
    <div
      style="position: relative;"
      class="flex align-center"
      class:center={primary.image_size === 'Small' ||
        primary.image_size === 'Medium' ||
        !primary.image_size}
      class:col-4={primary.image_size === 'Small'}
      class:col-6={primary.image_size === 'Medium' || !primary.image_size}
      class:col-8={primary.image_size === 'Large'}
    >
      <Image
        size={primary.dimensions}
        src={primary.image.url}
        alt={primary.image.alt}
        srcSet={primary.image}
      />

      <div
        class="container container-button on-top"
        style={`top: calc(50% - ${72 - buttonOffset}px)`}
      >
        <a href={primary.link}>
          <Button large>{primary.text}</Button>
        </a>
      </div>
    </div>
  </div>
{:else if buttonPlacement === 'Left' || buttonPlacement === 'Right'}
  <div class="container">
    {#if Array.isArray(primary.heading)}
      {@html PrismicDOM.RichText.asHtml(primary.heading, linkResolver)}
    {/if}
    <div class="grid" class:reversed={primary.button_placement === 'Right'}>
      <div
        class="flex align-center"
        class:center={primary.image_size === 'Small' ||
          primary.image_size === 'Medium' ||
          !primary.image_size}
        class:col-8={primary.image_size === 'Small'}
        class:col-6={primary.image_size === 'Medium' || !primary.image_size}
        class:col-4={primary.image_size === 'Large'}
      >
        <div
          class="container container-button"
          style={`margin-top: ${buttonOffset}px`}
        >
          <a href={primary.link}>
            <Button large>{primary.text}</Button>
          </a>
        </div>
      </div>
      <div
        class="flex align-center"
        class:col-4={primary.image_size === 'Small'}
        class:col-6={primary.image_size === 'Medium' || !primary.image_size}
        class:col-8={primary.image_size === 'Large'}
      >
        <Image
          size={primary.dimensions}
          src={primary.image.url}
          alt={primary.image.alt}
          srcSet={primary.image}
        />
      </div>
    </div>
  </div>
{/if}

<style>
  .container-button {
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
  }
  .on-top {
    position: absolute;
    top: calc(50% - 24px);
  }
  .flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .center {
    margin-left: auto;
    margin-right: auto;
  }
  .reversed {
    flex-direction: row-reverse;
  }
</style>
