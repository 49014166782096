<script>
  import PrismicDOM from 'prismic-dom';
  import { IMAGE_QUALITY } from '../constants';
  import { linkResolver } from '../utils/prismicHelpers';
  

  export let primary;

  const imageVw =
    primary.image_size === 'Small'
      ? '34vw'
      : primary.image_size === 'Medium'
      ? '50vw'
      : primary.image_size === 'Large'
      ? '66vw'
      : '100vw';

  let sizes;
  try {
    sizes = `(max-width: 600px) 100vw, (min width: ${primary.image1.sm.dimensions.width}px) 33w, (min width: ${primary.image1.md.dimensions.width}px) 50vw, (min width: ${primary.image1.lg.dimensions.width}px) 66vw, ${imageVw}`;
  } catch (error) {
    console.error(error, primary);
  }
</script>

<div class="container">
  <div
    class="text-image grid"
    class:reversed={primary.image_position === 'left'}
  >
    <div
      class={primary.image_size === 'Small'
        ? 'col-8'
        : primary.image_size === 'Medium'
        ? 'col-6'
        : primary.image_size === 'Large'
        ? 'col-4'
        : 'col-6'}
    >
      <div class="text-image__text">
        {#if primary.title1[0]}
          <h3 class="text-image__title">{primary.title1[0].text}</h3>
        {/if}
        {@html PrismicDOM.RichText.asHtml(primary.text, linkResolver)}
      </div>
    </div>
    <div
      class={primary.image_size === 'Small'
        ? 'col-4'
        : primary.image_size === 'Medium'
        ? 'col-6'
        : primary.image_size === 'Large'
        ? 'col-8'
        : 'col-6'}
    >
      <div class="text-image__img">
        {#if primary.image1.sm && primary.image1.dimensions}
          <img
            src={`${primary.image1.sm.url}&q=${IMAGE_QUALITY}`}
            alt={primary.image1.alt}
            srcset={`${primary.image1.sm.url}&q=${IMAGE_QUALITY},${primary.image1.md.url}&q=${IMAGE_QUALITY} ${primary.image1.md.dimensions.width}w,${primary.image1.lg.url}&q=${IMAGE_QUALITY} ${primary.image1.lg.dimensions.width}w,${primary.image1.xl.url}&q=${IMAGE_QUALITY} ${primary.image1.xl.dimensions.width}w`}
            {sizes}
          />
        {:else}
          <img src={primary.image1.url} alt={primary.image1.alt} />
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .text-image {
    padding: 40px 0;
  }
  .text-image__text {
    padding: 0 20px 20px;
  }
  .text-image__img {
    padding: 20px 20px 0;
  }
  .text-image__img img {
    width: 100%;
  }
  .text-image__title {
    text-align: left;
    margin-bottom: 21px;
  }
  .grid.reversed {
    flex-direction: row-reverse;
  }
</style>
